* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --heights: 60vh;
  --widths: 100%;
}

.slider-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.slide-title {
  font-size: 58px;
  font-weight: bolder;
  text-transform: uppercase;
  text-shadow: 1rem;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 58px;
  position: absolute;
  text-align: center;
  top: 40%;
  z-index: 10;
}

.slide-text {
  top: 65%;
  font-size: 2rem;
  font-weight: 600;
  text-transform: lowercase;
}

.prev,
.next {
  color: white;
  /* background: rgba(27, 216, 27, 0.844); */
  /* background: #133042; */
  background: #2c2c2b;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 20px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}

.slider-container:hover .prev,
.slider-container:hover .next {
  color: black;
}

.slider-container:hover .prev:hover,
.slider-container:hover .next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 10;
}

.dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin: 0px 3px;
  background-color: #980100;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
}

.slider-container:hover .dot:hover {
  background-color: #980100
}

/* .active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  } */

.slider-container:hover .dot {
  background-color: rgba(0, 0, 0, 0.3);
}
.slider-container:hover .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
}

.play-pause {
  color: black;
}

@media only screen and (max-width: 600px) {
  .slide-title {
    font-size: 36px;
  }
  .slide-text {
    font-size: 1.5rem;
    top: 55%;
  }
}
