.hero {
  background-image: url('../../../public/images/Hero\ png.png');
  /* background-image: url("../../../../public/images/banner.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 70vh;
  width: 100%;
}
.hero .container {
  padding-top: 10%;
}
.hero h1 {
  color: #fff;
  font-size: 55px;
}
.hero p {
  color: #fff;
  opacity: 0.8;
}
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 22%;
  }
  .hero h1 {
    font-size: 24px;
  }
}
/* @media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 22%;
  }
  .h1 {
    font-size: 18px;
  }
} */
